import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Col, Collapse, Button, Row } from 'react-bootstrap';

import Layout from '../components/layout';
import {
  Figure,
  List,
  Item,
  Url,
  Title,
  SubTitle,
  Excerpt,
  Tag,
} from '../styles/styled_tagpost';

const style = {
  transition: 'color 250ms',
  top: '1.63333em',
  padding: '0.25em',
  lineHeight: '1',
};

const TagPost = ({ pageContext }) => {
  const [open, setOpen] = useState(false);
  const { posts, tagName, tags } = pageContext;
  const upperTag = tagName.charAt(0).toUpperCase() + tagName.slice(1);

  return (
    <Layout>
      {/* TODO: Helmet component here */}
      <Col
        md={10}
        className="list-articles mx-auto"
        style={{ paddingTop: 100 }}
      >
        <div className="main">
          <Row
            className="figure"
            style={{
              display: 'block',
              margin: '0 auto',
              padding: '0.83333em',
            }}
          >
            <Link
              className="category link"
              to={`/blog/tag/${tagName}`}
              style={{
                color: '#d5d5d5',
                fontWeight: 'bold',
                right: '0.43333em',
                border: 'solid #2d2d30',
                backgroundColor: '#2d2d30',
                textDecoration: 'none',
                ...style,
              }}
            >
              {upperTag}
            </Link>

            <Button
              className="category link"
              onClick={() => setOpen(!open)}
              aria-controls="all-tags"
              aria-expanded={open}
              style={{
                float: 'right',
                color: '#000',
                border: 'none',
                fontSize: '1.6tem',
                background: 'none',
                ...style,
              }}
            >
              <i className="fas fa-tags"></i> See all tags
            </Button>
            <Collapse in={open}>
              <div style={{ marginTop: '30px' }}>
                <Row style={{ marginLeft: 0 }}>
                  {tags &&
                    tags.map((tag) => {
                      if (tag !== tagName) {
                        return (
                          <Tag
                            key={tag}
                            className="tag button"
                            to={`/blog/tag/${tag}`}
                          >
                            {tag}
                          </Tag>
                        );
                      }
                    })}
                </Row>
              </div>
            </Collapse>
          </Row>

          <Figure className="post listing figure">
            <List className="list">
              {posts.map((post, index) => (
                <Item className="item" key={index}>
                  <Url className="link" to={post.fields.slug}>
                    <Title className="title">
                      {post.frontmatter.title}
                      <SubTitle className="sub meta">
                        by Bill | {post.frontmatter.date}
                      </SubTitle>
                    </Title>
                    <Excerpt className="excerpt">{post.excerpt}</Excerpt>
                  </Url>
                </Item>
              ))}
            </List>
          </Figure>
        </div>
      </Col>
    </Layout>
  );
};

export default TagPost;

TagPost.propTypes = {
  pageContext: PropTypes.shape({
    posts: PropTypes.array,
    tagName: PropTypes.string,
    tags: PropTypes.array,
  }),
};
