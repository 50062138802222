import styled from '@emotion/styled';
import { Link } from 'gatsby';

export const Figure = styled.figure`
  color: hsla(0, 0%, 0%, 0.7);
  font-family: 'Lato', 'serif';
  font-weight: normal;
  font-kerning: normal;
  font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
  padding: 0.83333em;
`;

export const Url = styled(Link)`
  ul li &:hover h2 {
    color: #2d2d30 !important;
  }
  text-decoration: none !important;
`;

export const List = styled.ul`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const Item = styled.li`
  padding-left: 0;
  margin-bottom: 1.83333em;
`;

export const Title = styled.h2`
  margin: 0 0 0.43333em;
  padding: 0;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  color: #abcf1b !important;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1.51572rem;
  position: relative;
  line-height: 1.25;
`;

export const SubTitle = styled.small`
  font-family: 'Arial', 'sans-serif';
  font-weight: bold;
  display: block;
  color: #2d2d30;
  font-size: 50%;
`;

export const Excerpt = styled.p`
  font-weight: inherit;
  margin: 0;
  padding: 0;
  color: #4c4c4c;
`;

export const Tag = styled(Link)`
  margin-right: 0.5em;
  margin-top: 0.5em;
  padding: 0.45em 0.5em;
  outline: none;
  border: solid #2d2d30;
  background-color: #2d2d30;
  color: #fff;
  font-family: Arial, 'sans-serif';
  font-size: 75%;
  text-transform: uppercase;
  :hover {
    text-decoration: none;
    color: #ccff00;
  }
`;
